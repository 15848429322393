import global from './global.json';
import validation from './validation.json';
import tabs from './tabs.json';
import policeAccess from './policyAccess.json';
import footer from './footer.json';
import rating from './rating.json';
import errors from './errors.json';
export default {
  ...global,
  ...validation,
  ...tabs,
  ...policeAccess,
  ...footer,
  ...rating,
  ...errors,
};
